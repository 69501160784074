import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    meta: { noguard: true },
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    meta: { noguard: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue'),
  },
  {
    path: '/new-password/:token',
    name: 'NewPassword',
    meta: { noguard: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Lostpassword.vue'),
  },
  {
    path: '/tasks/:taskId/videoreview',
    name: 'VideoReview',
    meta: { canaccess: ['FULLACCESS', 'EMPLOYEE'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Tasks/VideoReviewView.vue'),
  },
  {
    path: '/review/video/:ident/:next?/:taskid?',
    name: 'VideoReviewClient',
    meta: { noguard: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clientviews" */ '../views/Tasks/VideoReviewClientView.vue'),
  },
  {
    path: '/review/photo/:ident/:next?/:taskid?',
    name: 'PhotoReviewClient',
    meta: { noguard: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clientviews" */ '../views/Tasks/PhotoReviewClientView.vue'),
  },
  {
    path: '/tasks/:taskId/photoreview',
    name: 'PhotoReview',
    meta: { canaccess: ['FULLACCESS', 'EMPLOYEE'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Tasks/PhotoReviewView.vue'),
  },
  {
    path: '/admin/ledgers',
    name: 'Ledger',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Admin/Ledgers/Root.vue'),
  },
  {
    path: '/admin/mailing-campaigns',
    name: 'MailingCampaignDashboard',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Admin/MailingCampaigns/MailingCampaignDashboard.vue'),
  },
  {
    path: '/admin/mailing-campaigns/form',
    name: 'MailingCampaignForm',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Admin/MailingCampaigns/MailingCampaignForm.vue'),
  },
  {
    path: '/admin/tasks',
    name: 'Task',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Admin/Tasks/Root.vue'),
  },
  {
    path: '/admin/tasks/new',
    name: 'NewTaskQueue',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Admin/Tasks/NewTaskQueue.vue'),
  },
  {
    path: '/admin/tasks/edit/:taskqueueid/:taskId?',
    name: 'EditTaskQueue',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Admin/Tasks/EditTaskQueue.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { canaccess: ['FULLACCESS', 'EMPLOYEE'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Root.vue'),
  },
  {
    path: '/preferences',
    name: 'Preferences',
    meta: { canaccess: ['FULLACCESS', 'EMPLOYEE'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Userpreferences.vue'),
  },
  {
    path: '/userdetails',
    name: 'Userdetails',
    meta: { canaccess: ['FULLACCESS', 'EMPLOYEE'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Userdetails.vue'),
  },
  {
    path: '/tests',
    name: 'Tests',
    meta: { noguard: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tests" */ '../views/Tests/Root.vue'),
  },
  {
    path: '/tests/:permalink/attempt/',
    name: 'TestAttempt',
    meta: { noguard: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tests" */ '../views/Tests/Attempt.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Root.vue'),
  },
  {
    path: '/admin/employees',
    name: 'AdminEmployees',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Employees/Root.vue'),
  },
  {
    path: '/admin/employees/:employeeid',
    name: 'AdminEmployeesSelect',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Employees/Root.vue'),
  },
  {
    path: '/admin/employees/:employeeId/history',
    name: 'AdminEmployeeTaskHistory',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Employees/TaskHistory.vue'),
  },
  {
    path: '/admin/tasks-configs',
    name: 'AdminTasksConfigs',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Taskconfigs/Root.vue'),
  },
  {
    path: '/admin/tasks-configs/template',
    name: 'AdminTaskTemplate',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Taskconfigs/Tasktemplate.vue'),
  },
  {
    path: '/admin/customers',
    name: 'AdminCustomers',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Customers/Root.vue'),
  },
  {
    path: '/admin/customer-add',
    name: 'AdminCustomerAdd',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Customers/Add.vue'),
  },
  {
    path: '/admin/customer-details/:customerid',
    name: 'AdminCustomerInfos',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Customers/Details.vue'),
  },
  {
    path: '/admin/customer-project/:customerid/:projectid',
    name: 'AdminCustomerProject',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Customers/ProjectDetails.vue'),
  },
  {
    path: '/admin/tests',
    name: 'AdminTests',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admintests" */ '../views/Admin/Tests/Root.vue'),
  },
  {
    path: '/admin/tests/completed',
    name: 'CompletedTest',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "userdetail" */ '../views/Admin/Tests/CompletedTest.vue'),
  },
  {
    path: '/admin/tests/new',
    name: 'NewTest',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admintests" */ '../views/Admin/Tests/NewTest.vue'),
  },
  {
    path: '/admin/tests/edit/:id',
    name: 'EditTest',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admintests" */ '../views/Admin/Tests/EditTest.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
