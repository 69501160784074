export const ENGLISH_TRANSLATIONS = {
  login: {
    log_in: 'Log in',
    your_email: 'Your email',
    your_password: 'Your password',
    login: 'login',
    lost_password: 'I lost my password / I don\'t have a password',
    reset_password: 'Reset my password',
    change_your_password: 'Change your password',
    your_new_password: 'Your new password',
  },
  homepage: {
    welcome: 'Welcome!',
  },
  errors: {
    login: {
      wrong_username_password: 'Invalid email address or password',
    },
  },
};
