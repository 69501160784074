<template>
  <mdb-container fluid class="classic-form-page pt-5">
    <mdb-row class="justify-content-around pt-5 pb-5 pt-3">
      <mdb-col md="6" class="text-center">
        <img src="/images/PLUSCREW-LOGO.png" class="img-fluid" />
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-around">
      <mdb-col class="text-center">
        <a href="/dashboard" style="color:#000;" class="font-weight-bold">Access the dashboard</a>
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
} from 'mdbvue';

export default {
  name: 'Home',
  data() {
    return {
      dummy: null,
    };
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
  },
  methods: {
    gotodashboard() {
      console.log('nada');
    },
  },
};
</script>

<style scoped>
.classic-form-page {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  height: 100vh;
  width: 100%;
  background-color: #f5d0ff;
}
body{
  background-color: RED;
}
</style>
