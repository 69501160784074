import Vue from 'vue';
import { Route } from 'vue-router';
import Vuex from 'vuex';

Vue.use(Vuex);

interface AppStore {
    id: number|null,
    userisloggued: boolean,
    token: string,
    firstname: string,
    lastname: string,
    email: string,
    accesslevel: string,
    currentlang: string,
    intendedurl: null|Route,
    isndasigned: null|string,
    haspaymentinfo: boolean,
    inprobation: string,
}

export default new Vuex.Store({
  state: {
    id: null,
    userisloggued: false,
    token: '',
    firstname: '',
    lastname: '',
    email: '',
    accesslevel: '',
    currentlang: 'en',
    intendedurl: null,
    isndasigned: null,
    haspaymentinfo: false,
    inprobation: '0',
  } as AppStore,
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
