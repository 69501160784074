import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbvue/lib/css/mdb.min.css';
import Notify from 'mdbvue/lib/components/Notify';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import Auth from './libs/Auth';
import { ENGLISH_TRANSLATIONS } from './translations/en';
import { FRENCH_TRANSLATIONS } from './translations/fr';

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(Notify);

const TRANSLATIONS = {
  en: ENGLISH_TRANSLATIONS,
  fr: FRENCH_TRANSLATIONS,
};

let currentlang = window.localStorage.getItem('currentlang');
if (!currentlang) {
  currentlang = 'en';
}
store.state.currentlang = currentlang;
window.localStorage.setItem('currentlang', currentlang);

const i18n = new VueI18n({
  locale: store.state.currentlang,
  messages: TRANSLATIONS,
});

router.beforeEach((to, from, next) => {
  let noguard = false;
  if (typeof to.meta !== 'undefined') {
    noguard = to.meta.noguard;
  }
  const auth = new Auth();
  if (noguard !== true) {
    auth.isLogged().then((response) => {
      if (response) {
        if (auth.canAccess(to)) {
          next();
        } else {
          next({ name: 'Login' });
        }
      } else {
        if (to.name !== '404') {
          store.state.intendedurl = to;
        }
        next({ name: 'Login' });
      }
    });
  } else {
    auth.isLogged().then((response) => { next(); });
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
