export const FRENCH_TRANSLATIONS = {
  login: {
    log_in: 'Connexion',
    your_email: 'Adresse courriel',
    your_password: 'Mot de passe',
    login: 'Connexion',
    lost_password: 'J\'ai perdu mon mot de passe',
    reset_password: 'Réinitialiser mon mot de passe',
    change_your_password: 'Modifiez votre mot de passe',
    your_new_password: 'Votre nouveau mot de passe',
  },
  homepage: {
    welcome: 'Bienvenue!',
  },
  errors: {
    login: {
      wrong_username_password: 'Adresse courriel ou mot de passe invalide',
    },
  },
};
